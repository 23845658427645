export const products = [
    {
        id: '2b5dd18a-bb81-477f-b6c8-fecc17eebfb3',
        name: "Silver Plan", 
        currency: "USD",
        description: "Silver Plan: Invest $10-$1000, earn 1.25% daily for 175 days, including principal. Explore steady growth with Coinpulse Online Financial Service.",
        dailyPercentage:1.25,
        totalDays:175
    },
    {
        id: 'c1f8baf9-4248-4f65-8b4c-bca96d8d9de4',
        name: "Bronze plan", 
        currency: "USD",
        description: "Bronze plan: Invest $1001-$5000, earn 1.5% daily for 150 days. Join now for attractive returns on your crypto investments.",
        dailyPercentage:1.50,
        totalDays:150
    },
    {
        id: '55a0e1d8-2795-4472-8a4e-3680ba724049',
        name: "Copper Plan", 
        currency: "USD",
        description: "Copper Plan: Maximize Returns with our high-yield investment plan for crypto lovers. Daily returns of 1.75% for 100 days. Join now!",
        dailyPercentage:1.75,
        totalDays:100
    },
    {
        id: '67c6aaa9-6f51-475a-b9f5-171df6791df9',
        name: "Gold Plan", 
        currency: "USD",
        description: "Maximize your crypto investments with Coinpulse Online Financial Service Gold Plan: daily returns of 2.1% for 90 days, principal included, and a maximum deposit of $100K.",
        dailyPercentage:2.1,
        totalDays:90
    }  ,
    {
        id: 'b1754c14-d296-4b0f-a09a-030017f4461f',
        name: "Hot Plan", 
        currency: "USD",
        description: "🔥 Introducing the Hot Plan! 🔥 Perfect for beginners, this plan boasts a low entry barrier with a minimum deposit of $50 and a maximum of $10,000. Investors can bask in a daily return of 5.1% for a duration of 30 days, with the principal amount included in the daily return. 💰💼",
        dailyPercentage:5.1,
        totalDays:30
    }  
]