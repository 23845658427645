function networkType(network?: string) {

    let networkIcon = ''
    switch (network) {
        case 'usdc':
            networkIcon = '/usdc.png';
            break;
        case 'tether':
            networkIcon = '/usdt.png';
            break;
        case 'apecoin':
            networkIcon = '/app.png';
            break;
        case 'bitcoin':
            networkIcon = '/btc.png';
            break;
        case 'polygon':
            networkIcon = '/matic.png';
            break;
        case 'dogecoin':
            networkIcon = '/doge.png';
            break;
        case 'ethereum':
            networkIcon = '/eth.png';
            break;
        case 'litecoin':
            networkIcon = '/ltc.png';
            break;
        case 'cardano':
            networkIcon = '/ada.png';
            break;
        case 'troncoin':
            networkIcon = '/trx.png';
            break;
        case 'solana':
            networkIcon = '/sol.png';
            break;
        case 'binance':
            networkIcon = '/bnb.png';
            break;
        case 'bitcoincash':
            networkIcon = '/bch.png';
            break;
        case 'coinpulse':
            networkIcon = '/cpoLight.png';
            break;
        case 'USDC':
            networkIcon = '/usdc.png';
            break;
        case 'USDT':
            networkIcon = '/usdt.png';
            break;
        case 'BTC':
            networkIcon = '/btc.png';
            break;
        case 'MATIC':
            networkIcon = '/matic.png';
            break;
        case 'DOGE':
            networkIcon = '/doge.png';
            break;
        case 'ETH':
            networkIcon = '/eth.png';
            break;
        case 'LTC':
            networkIcon = '/ltc.png';
            break;
        case 'SHIB':
            networkIcon = '/shib.png';
            break;
        case 'BCH':
            networkIcon = '/bch.png';
            break;
        case 'CPO':
            networkIcon = '/cpoLight.png';
            break; 
        case 'ADA':
            networkIcon = '/ada.png';
            break;
        case 'TRX':
            networkIcon = '/trx.png';
            break;
        case 'SOL':
            networkIcon = '/sol.png';
            break;
        case 'BNB':
            networkIcon = '/bnb.png';
            break;
        default:
            break;
    }

    return networkIcon
}

export { networkType }