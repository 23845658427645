import { ConnectButton } from '@rainbow-me/rainbowkit'
import Box from '@/components/primitives/Box' 
import { FC } from 'react' 
type Props = {}

export const MobileConnectWallet: FC<Props> = () => {
    return (
        <ConnectButton.Custom>
            {({ account, chain, openConnectModal, mounted }) => {
                return (
                    <Box>
                        {(() => {
                            if (!mounted || !account || !chain) {
                                return (
                                    <w3m-button size='sm' balance='show'  label='Login'/>
                                )
                            }
                        })()}
                    </Box>
                )
            }}
        </ConnectButton.Custom>
    )
}
