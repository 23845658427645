import { client } from '@/utils/sanity'
import { products } from '@/data'
import { v4 as uuid } from 'uuid';
import { networkType } from '@/utils/network';
import * as OTPAuth from "otpauth";
import { truncateAddress } from '@/utils/truncate';
const postOptions = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
        'Content-Type': 'application/json',
    },
}


export function getRandom(length) {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
}

export const GetUserById = async (userId, sanityClient = client) => {
    const query = `*[_type == "users" && _id == "${userId?.toLowerCase()}"]`
    const user = await sanityClient.fetch(query)
    return { data: user }
}

export const GetIsClaimed = async (userId, sanityClient = client) => {
    const query = `*[_type == "users" && _id == "${userId?.toLowerCase()}" && (isClaimed==true)]{
        isClaimed
    }`
    const user = await sanityClient
        .fetch(query)
    return { data: user }
}

export const Getwithdraw = async () => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/getwithdraw`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
    const result = await res.json()
    return { result }
}

export const GetInvestments = async () => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/getinvestors`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
    const result = await res.json()
    return { result }
}

export const CreateNewUser = async (userId, ownId, parentId, firstName = '', lastName = '', username = 'Unnamed', sanityClient = client) => {
    const referralId = Number(ownId) > 0 ? ownId : getRandom(8)

    const referralLink = process.env.NEXT_PUBLIC_HOST_URL + '?referralId=' + referralId
    const query = `*[_type == "users" && _id == "${userId?.toLowerCase()}" ] {
      walletAddress}`

    await sanityClient
        .fetch(query)
        .then((result) => {
            const userData = result[0]
            if (userData?.walletAddress !== null) {
            }
            else {
                CreateUser(userId?.toLowerCase(), referralId, firstName, lastName, username, referralLink, Number(parentId) > 0 ? parentId : '', ownId)
                sessionStorage.removeItem("referralId");
            }
        })
        .catch((err) => {
            console.error('Delete failed: ', err.message)
        })
}

export const userByTelegramAsync = async (user) => {
    const transaction = client.transaction()
    const userDoc = {
        _type: 'users',
        _id: user?.userId?.toLowerCase(),
        telegramId: user?._id
    }
    await transaction
        .createIfNotExists(userDoc)
        .patch(userDoc._id, (patch) => patch.set(userDoc))
        .commit()
        .then(async (result) => {
        })
        .catch((error) => {
            console.log(error)
        })
}

const CreateUser = async (address, referralId, firstName, lastName, username, referralLink, parentId) => {
    const userDoc = {
        _type: 'users',
        _id: address?.toLowerCase(),
        userName: username,
        firstName: firstName,
        lastName: lastName,
        referralId: referralId,
        referralLink: referralLink,
        parentId: parentId,
        isClaimed: false,
        walletAddress: address.toLowerCase() || undefined,
    }


    try {
        const transaction = client.transaction()
        const result = await transaction.createIfNotExists(userDoc)
            .patch(userDoc._id, (patch) => patch.set(userDoc))
            .commit()
            .then(async () => {
                const userBalance = {
                    _type: 'usersBalance',
                    _id: uuid(),
                    userId: address?.toLowerCase(),
                    telegramId: 0,
                    yesterdayBalance: 0,
                    totalBalance: 0,
                    btcBalance: 0,
                    ltcBalance: 0,
                    ethBalance: 0,
                    dogeBalance: 0,
                    apeBalance: 0,
                    usdtBalance: 0,
                    usdcBalance: 0,
                    shibBalance: 0,
                    maticBalance: 0,
                    adaBalance: 0,
                    trxBalance: 0,
                    solBalance: 0,
                    bnbBalance: 0,
                    bchBalance: 0,
                    cpoBalance: 50.00000000,
                    btcBalanceC: 0.00000000,
                    ltcBalanceC: 0.00000000,
                    ethBalanceC: 0.00000000,
                    dogeBalanceC: 0.00000000,
                    apeBalanceC: 0.00000000,
                    shibBalanceC: 0.00000000,
                    bnbBalanceC: 0.00000000,
                    maticBalanceC: 0.00000000,
                    bchBalanceC: 0.00000000,
                    promoBalance: 0.00000000,
                    firstLevelEarning: 0.00,
                    secondLevelEarning: 0.00,
                    thirdLevelEarning: 0.00
                }

                await client.createIfNotExists(userBalance, postOptions)
            })
            .catch((e) => {
                console.log(e)
            })

        return { data: result }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const IsUserActive = async (user_id, sanityClient = client) => {
    try {
        const query = `*[_type == "users" && walletAddress == "${user_id?.toLowerCase()}"  && isVerified==true ]{
            "userId": walletAddress
    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const SuccessfullyClaimed = async (user_id, sanityClient = client) => {
    const transaction = sanityClient.transaction()
    try {
        const query = {
            _type: 'users',
            _id: user_id?.toLowerCase(),
            isClaimed: true
        }
        await transaction
            .createIfNotExists(query)
            .patch(query._id, (patch) => patch.set(query))
            .commit()
            .then((result) => {
            })
            .catch((error) => {
                console.log(error)
            })

    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const SuccessfullyEnableCloudMining = async (data, sanityClient = client) => {

    try {
        const jsonData = JSON.stringify(data)
        const res = await fetch(
            `${process.env.NEXT_PUBLIC_HOST_URL}/api/cloudMining`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: jsonData
            })
        const result = await res.json()
        return { result }

    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const SuccessfullyEnable10XCloudMining = async (data, sanityClient = client) => {
    try {
        const jsonData = JSON.stringify(data)
        const res = await fetch(`${process.env.NEXT_PUBLIC_HOST_URL}/api/10XCloudMining`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: jsonData
            })
        const result = await res.json()
        return { result }

    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const ActiveUserEarning = async (user_id, sanityClient = client) => {
    const transaction = sanityClient.transaction()
    try {
        const query = {
            _type: 'users',
            _id: user_id?.toLowerCase(),
            isVerified: true
        }
        await transaction
            .createIfNotExists(query)
            .patch(query._id, (patch) => patch.set(query))
            .commit()
            .then((result) => {
            })
            .catch((error) => {
                console.log(error)
            })

    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}
export const GetVerifiedUser = async (sanityClient = client) => {
    try {
        const query = `*[_type == "users"  && isVerified==true ]{
            "userId": walletAddress
    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })

        return { data: userData }
    }
    catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetCloudeMiningUsers = async (sanityClient = client) => {
    try {
        const query = `*[_type == "users"  && isCloudMining==true ]{
            "userId": walletAddress
    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })

        return { data: userData }
    }
    catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const CreateDailyAndMonthlyFirstRecord = async (sanityClient = client) => {
    try {
        const query = `*[_type == "users"  && isVerified==true ]{
            "userId": walletAddress
    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })

        userData?.map(async (user, index, arr) => {

            let dailyBalanceData = await GetDailyEarning(user?.userId?.toLowerCase())
            let monthlyBalanceData = await GetMonthlyEarning(user?.userId?.toLowerCase())

            const timeStamp = Date.now()
            const date = new Date(timeStamp);
            if (dailyBalanceData?.data?.length == 0) {
                const dailyEarning_unique_id = uuid();
                const dailyEarning = {
                    _type: 'dailyEarning',
                    _id: dailyEarning_unique_id,
                    userId: user?.userId?.toLowerCase(),
                    day: date.getDate(),
                    month: monthLocalizedString(date.getMonth(), 'en'),
                    year: date.getFullYear(),
                    todayEarning: 0,
                }

                await sanityClient
                    .createIfNotExists(dailyEarning, postOptions)
                    .then((result) => {
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            if (monthlyBalanceData?.data?.length == 0) {
                const monthlyEarning_unique_id = uuid();
                const monthlyEarning = {
                    _type: 'monthlyEarning',
                    _id: monthlyEarning_unique_id,
                    userId: user?.userId?.toLowerCase(),
                    month: monthLocalizedString(date.getMonth(), 'en'),
                    year: date.getFullYear(),
                    monthlyEarning: 0,
                }

                await sanityClient
                    .createIfNotExists(monthlyEarning, postOptions)
                    .then((result) => {
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

        })

    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const BlockchainActiveDeposit = async (depositData, isWalletConnected) => {
    try {
        const result = await client.createIfNotExists(depositData, postOptions).then(async () => {
        }).catch((e) => {
            console.log(e)
        })
        return { data: result }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetAllUserDeposit = async (sanityClient = client) => {
    const query = `*[_type == "usersDeposit" && isTelegtam==true][1...18000]._id`

    const userData = await sanityClient
        .fetch(query)
        .catch((err) => {
            console.error('Not Get Any Data: ', err)
        })

    var k = 0;
    while (k < userData.length) {
        let docId = userData[k]
        k++;
        console.log(k)
        await sanityClient.delete(docId.toString()).then(() => {
            console.log('Telegram deleted balance record deleted')
        }).catch((err) => { console.error('Delete failed: ', err.message) })

    }

    // userData?.map(async (data) => {
    //     const JSONdata = JSON.stringify(data)
    //     const res = await fetch(
    //         `${process.env.NEXT_PUBLIC_HOST_URL}/api/saveuserinvestment`,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSONdata
    //         })
    //     const result = await res.json()
    // })
    return { data: userData }
}

export const SaveMining = async (data, sanityClient = client) => {
    const jsonData = JSON.stringify(data)
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/saveMining`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData
        })
    const result = await res.json()
    return { result }
}

export const LastMiningSave = async (data, sanityClient = client) => {
    const jsonData = JSON.stringify(data)
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/lastminingsave`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData
        })
    const result = await res.json()
    return { result }
}

export const GetMining = async (data, sanityClient = client) => {
    const jsonData = JSON.stringify(data)
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/getMiningData`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData
        })
    const result = await res.json()
    return { result }
}

export const CheckLastWithdraw = async (data, sanityClient = client) => {
    const jsonData = JSON.stringify(data)
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/checkLastWithdraw`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData
        })
    const result = await res.json()
    return { result }
}

export const GetActiveDepositsByUser = async (user_id, sanityClient = client) => {
    try {
        const query = `*[_type == "usersDeposit" && userId == "${user_id?.toLowerCase()}" && depositStatus==true] | order(_createdAt desc) 
    {
        _id,
        userId,
        planId,
        depositId,
        depositAmount,
        transectionId,
        hostedUrl,
        depositCode,
        usdAmount,
        network,
        depositAddress,
        depositStatus,
        depositType,
        totalReturn,
        percentage, 
        expiryDate,
        transactionHash,
        expectedEarning, 
        _createdAt
    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}
export const GetAllDepositsByUser = async (user_id, sanityClient = client) => {
    try {
        const query = `*[_type == "usersDeposit" && userId == "${user_id?.toLowerCase()}"] | order(_createdAt desc) 
    {
        userId,
        planId,
        depositId,
        depositAmount,
        transectionId,
        hostedUrl,
        depositCode,
        usdAmount,
        network,
        depositAddress,
        depositStatus,
        depositType,
        totalReturn,
        percentage, 
        expiryDate,
        transactionHash,
        expectedEarning, 
        _createdAt
    }`

        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}
//const query = `*[_type == "usersDeposit" && depositStatus==true && nextEarningTime <=${todayDate} && expiryDate>="${todayDate}"]  | order(_createdAt asc)

export const GetTelegramUserBonusHistoryBy = async (userId, isWalletConnected, sanityClient = client) => {
    console.log(isWalletConnected)
    if (!isWalletConnected) {
        const query = `*[_type == "earningHistory" && planId ==10 && telegramId == ${userId}]| order(_createdAt desc)[0...20]  
        {
            userId,
            planId,
            drpositId,
            network,
            earningAmount,
            isPromo,
            cryptoEarning, ,
            isReferral,
            referralLevel,
            investorName,
            _createdAt
    
        }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } else {
        const query = `*[_type == "earningHistory" && planId ==10 &&  userId == "${userId}"]| order(_createdAt desc)[0...20]  
        {
            userId,
            planId,
            drpositId,
            network,
            isPromo,
            earningAmount,
            cryptoEarning, 
            isReferral,
            referralLevel,
            investorName,
            _createdAt
    
        }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    }
}
export const GetTelegramUserCommissionsHistoryBy = async (telegramId, sanityClient = client) => {
    try {
        const query = `*[_type == "earningHistory" && isReferral==true && planId <=5 && telegramId == ${telegramId}]| order(_createdAt desc)[0...20]  
    {
        userId,
        planId,
        drpositId,
        network,
        earningAmount,
        cryptoEarning, 
        isReferral,
        referralLevel,
        investorName,
        _createdAt

    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetTelegramUserInvestmentHistoryBy = async (telegramId, sanityClient = client) => {
    try {
        const query = `*[_type == "earningHistory" && isReferral==false && planId!=10 && telegramId==${telegramId}]| order(_createdAt desc)[0...20]  
        {
            telegramId,
            userId,
            planId,
            drpositId,
            network,
            earningAmount,
            cryptoEarning, 
            isReferral,
            isPromo,
            referralLevel,
            investorName,
            _createdAt 
        }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetTelegramUserEarningHistoryBy = async (telegramId, sanityClient = client) => {
    try {
        const query = `*[_type == "earningHistory" && telegramId == ${telegramId}]| order(_createdAt desc)[0...20]  
    {
        userId,
        planId,
        drpositId,
        network,
        earningAmount,
        cryptoEarning, 
        isReferral,
        referralLevel,
        investorName,
        _createdAt

    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetEarningHistoryByUser = async (user_id, sanityClient = client) => {
    try {
        const query = `*[_type == "earningHistory" && userId == "${user_id?.toLowerCase()}"] | order(_createdAt desc) 
    {
        userId,
        planId,
        drpositId,
        network,
        earningAmount,
        cryptoEarning, 
        isReferral,
        referralLevel,
        investorName,
        _createdAt

    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetReferralEarningByUser = async (user_id, sanityClient = client) => {
    try {
        const query = `*[_type == "earningHistory" && isReferral==true && userId == "${user_id?.toLowerCase()}"] | order(_createdAt desc) 
    {
        userId,
        planId,
        drpositId,
        network,
        earningAmount,
        cryptoEarning, 
        isReferral,
        referralLevel,
        investorName,
        _createdAt

    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetActiveDepositData = async (sanityClient = client) => {
    const todayDate = Date.now()

    try {
        const query = `*[_type == "usersDeposit" && depositStatus==true && nextEarningTime <=${todayDate} && expiryDate>="${todayDate}"]  | order(_createdAt asc)
    {
        _id,
        userId,
        planId,
        depositId,
        depositAmount,
        transectionId,
        hostedUrl,
        depositCode,
        usdAmount,
        network,
        depositAddress,
        depositStatus,
        depositType,
        totalReturn,
        percentage, 
        expiryDate,
        nextEarningTime,
        transactionHash,
        expectedEarning, 
        _createdAt
    }`

        const depositEarning = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })

        return { data: depositEarning }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}



export const GetWithdrawListByUser = async (user_id, sanityClient = client) => {
    try {
        const query = `*[_type == "withdrawHistory" && userId == "${user_id?.toLowerCase()}"] | order(_createdAt desc) 
    {
        _id,
        userId,
        network,
        withdrawAmoutUSD,
        withdrawAmoutCrypto,
        withdrawAddress,
        transactionId, 
        isConfirmed,
        isRejected, 
        "Rejected":select(isRejected==true=>'Rejected', '.'),
        "Status":select(isConfirmed==true=>'Completed', 'Under process'),
        "EmailVerify":select(isEmailVerify==true=>'Completed', 'Pending'), 
        _createdAt
    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetWithdrawList = async (sanityClient = client) => {
    try {
        const query = `*[_type == "withdrawHistory" && isConfirmed == false] | order(_createdAt desc) 
    {
        _id,
        userId,
        network,
        withdrawAmoutUSD,
        withdrawAmoutCrypto,
        withdrawAddress,
        transactionId, 
        isConfirmed,
        isRejected,
        _createdAt
    }`
        const userData = await sanityClient
            .fetch(query).catch((e) => { console.log(e) })
        return { data: userData }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const InvestmentSummery = async (user_id) => {
    const balance = await (await GetBalanceByUserId(user_id, true))?.data[0]
    const WithdrawList = await (await GetWithdrawListByUser(user_id))?.data
    const depositList = await (await GetAllDepositsByUser(user_id))?.data
    const yesterdayBalance = balance?.yesterdayBalance
    const avilableBalance = balance?.totalBalance
    const cpoBalance = balance?.cpoBalance == null ? 0 : balance?.cpoBalance

    const totalDeposit = depositList?.reduce(function (s, a) {
        return s + a.usdAmount;
    }, 0);
    const activeDeposit = depositList?.filter(x => x.depositStatus === true).reduce(function (s, a) {
        return s + a.usdAmount;
    }, 0);
    const expiredDeposit = depositList?.filter(x => x.depositStatus === false).reduce(function (s, a) {
        return s + a.usdAmount;
    }, 0);
    const pendingWithdraw = WithdrawList?.filter(x => x.isConfirmed === null || x.isConfirmed === false).reduce(function (s, a) {
        return s + a.withdrawAmoutUSD;
    }, 0);
    const totalWithdraw = WithdrawList?.reduce(function (s, a) {
        return s + a.withdrawAmoutUSD;
    }, 0);
    const completedWithdraw = WithdrawList?.filter(x => x.isConfirmed === true).reduce(function (s, a) {
        return s + a.withdrawAmoutUSD;
    }, 0);
    const rejectedWithdraw = WithdrawList?.filter(x => x.isRejected === true).reduce(function (s, a) {
        return s + a.withdrawAmoutUSD;
    }, 0);
    return {
        data: {
            yesterdayBalance: yesterdayBalance,
            avilableBalance: avilableBalance,
            activeDeposit: activeDeposit,
            expiredDeposit: expiredDeposit,
            pendingWithdraw: pendingWithdraw,
            totalWithdraw: totalWithdraw,
            completedWithdraw: completedWithdraw,
            rejectedWithdraw: rejectedWithdraw,
            totalDeposit: totalDeposit,
            cpoBalance: cpoBalance
        }
    }
}

export const getProfileDta = async (address, sanityClient = client) => {
    const query = `*[_type == "users" && _id == "${address?.toLowerCase()}" ] 
    {
      _id,
      referralId, 
      parentId,
      referralLink, 
      firstName, 
      lastName, 
      profileName, 
      emailId, 
      mobileNumber, 
      creator, 
      aboutMe, 
      address, 
      city, 
      state, 
      country, 
      pinCode, 
      website, 
      bannerSrc, 
      profileSrc, 
      twitterHandle, 
      facebookHandle, 
      instagramHandle, 
      discordURL, 
      PaymentSetting,
      twoFACode,
      authenticationCode,
      isGoogleFactor,
      otpAuthUrl,
      otpBase32
    }`
    const result = await sanityClient
        .fetch(query)
        .catch((err) => {
            console.error('Delete failed: ', err.message)
        })
    return { data: result }
}

export const getParentLevel = async (parentId, sanityClient = client) => {
    const query = `*[_type == "users" && referralId == '${parentId}'] 
    {
      _id,
      referralId, 
      parentId, 
      firstName,
      _createdAt
    }`
    const result = await sanityClient
        .fetch(query)
        .catch((err) => {
            console.error('Delete failed: ', err.message)
        })
    return { data: result }
}

export const getSelfReferrals = async (parentId, sanityClient = client) => {
    const query = `*[_type == "users" && parentId == '${parentId}'] 
    {
      _id,
      referralId, 
      parentId, 
      firstName,
      "status":select(isVerified==true=>'Active User', 'InActive'), 
      profileSrc,
      _createdAt
    }`
    const result = await sanityClient
        .fetch(query)
        .catch((err) => {
            console.error('Delete failed: ', err.message)
        })
    return { data: result }
}

export const getOwnReferrals = async (referralId, userId, sanityClient = client) => {

    const referrals = []
    const level1 = [];
    const level2 = [];
    const level3 = [];

    let activeLevel2 = [];
    let activeLevel3 = [];

    let firstLevelEarning = 0;
    let secondLevelEarning = 0;
    let thirdLevelEarning = 0;
    let totalReferralEarning = 0;
    let investmentAmount = 0;


    const ownReferral = await (await getSelfReferrals(referralId))?.data
    const firstLevelReferrals = ownReferral.filter(user => {
        return user.parentId == referralId;
    });

    const activeLevel1 = firstLevelReferrals.filter(user => {
        return user.isVerified == true;
    });

    if (activeLevel1.length > 0) {
        const referralEarning = await (await GetReferralEarningByUser(userId))?.data

        totalReferralEarning = referralEarning?.reduce(function (s, a) {
            return s + a.earningAmount;
        }, 0);

        investmentAmount = referralEarning?.reduce(function (s, a) {
            return s + a.investmentAmount;
        }, 0);

        firstLevelEarning = referralEarning?.filter(uesr => user.referralLevel == '1st Level').reduce(function (s, a) {
            return s + a.earningAmount;
        }, 0);

        secondLevelEarning = referralEarning?.filter(uesr => user.referralLevel == '2nd Level').reduce(function (s, a) {
            return s + a.earningAmount;
        }, 0);

        thirdLevelEarning = referralEarning?.filter(uesr => user.referralLevel == '3rd Level').reduce(function (s, a) {
            return s + a.earningAmount;
        }, 0);
    }

    firstLevelReferrals?.forEach((l1) => {
        referrals?.push({ _id: l1?._id, profileSrc: l1?.profileSrc, firstName: l1?.firstName, status: l1?.status, _createdAt: l1?._createdAt, refrralLevel: 'Level-🥇' });
        level1.push(l1?.referralId);
    });

    if (level1.length > 0) {
        const secondLevelReferrals = await (await getChildReferrals(level1))?.data

        secondLevelReferrals?.forEach((l2) => {
            referrals?.push({ _id: l2?._id, profileSrc: l2?.profileSrc, firstName: l2?.firstName, status: l2?.status, _createdAt: l2?._createdAt, refrralLevel: 'Level-🥈' });
            level2.push(l2?.referralId);
        });
        activeLevel2 = secondLevelReferrals.filter(user => {
            return user.isVerified == true;
        });

    }

    if (level2.length > 0) {
        const trirdLevelReferrals = await (await getChildReferrals(level2))?.data
        trirdLevelReferrals?.forEach((l3) => {
            referrals?.push({ _id: l3?._id, profileSrc: l3?.profileSrc, firstName: l3?.firstName, status: l3?.status, _createdAt: l3?._createdAt, refrralLevel: 'Level-🥉' });
            level3.push(l3?.referralId);
        });
        activeLevel3 = trirdLevelReferrals.filter(user => {
            return user.isVerified == true;
        });

    }

    return {
        data: {
            referrals: referrals,
            firstLevelTotalReferral: level1.length,
            secondLevelTotalReferral: level2?.length,
            thirdLevelTotalReferral: level3?.length,
            firstLevelActiveReferral: activeLevel1.length,
            secondLevelActiveReferral: activeLevel2?.length,
            thirdLevelActiveReferral: activeLevel3?.length,
            firstLevelTotalEarning: firstLevelEarning,
            secondLevelTotalEarning: secondLevelEarning,
            thirdLevelTotalEarning: thirdLevelEarning,
            totalRefEarning: totalReferralEarning,
            totalReferrals: referrals?.length,
            activeReferral: activeLevel1.length + activeLevel2?.length + activeLevel3?.length,
            investmentAmount: investmentAmount

        }
    }
}

export const getChildReferrals = async (referralIds, sanityClient = client) => {
    const query = `*[_type == "users"][parentId in [${referralIds}]] 
    {
        _id,
        referralId, 
        parentId, 
        firstName,
        "status":select(isVerified==true=>'Active User', 'InActive'), 
        profileSrc,
        _createdAt
    }`
    const result = await sanityClient
        .fetch(query)
        .catch((err) => {
            console.error('Delete failed: ', err.message)
        })
    return { data: result }
}

export const getAllReferrals = async (referralId, sanityClient = client) => {

}

export const ValidateCode = async (userData) => {
    const token = userData?.twoFACode
    const userDetail = await (await (getProfileDta(userData?._id))).data[0]
    let totp = new OTPAuth.TOTP({
        issuer: "Coinpulse Online",
        label: "CoinPulase",
        algorithm: "SHA1",
        digits: 6,
        period: 30,
        secret: userDetail?.otpBase32,
    });
    let delta = totp.validate({ token, window: 1 });
    if (delta == null) {
        return { data: "Token is invalid or user doesn't exist" }
    }
    return { data: true }

}

export const TwoFACodeSetting = async (userData, sanityClient = client) => {

    const transaction = client.transaction()
    await transaction
        .createIfNotExists(userData)
        .patch(userData._id, (patch) => patch.set(userData))
        .commit()
        .then((result) => {
            return { data: true }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const GetBannerUrl = async (address, sanityClient = client) => {
    try {
        const query = `*[_type == "users" && _id == "${address?.toLowerCase()}" ] 
    { 
      bannerSrc,
      isGoogleFactor,
      referralLink,
      referralId,
      parentId
    }`
        const bannerSrcURL = await sanityClient
            .fetch(query)
            .catch((err) => {
                console.error('Not Get Any Data: ', err.message)
            })
        return { data: bannerSrcURL }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const ProceedWithdrawRequest = async (data, sanityClient = client) => {
    const balanceDetail = await (await GetBalanceByUserId(data?.userId?.toLowerCase(), true))?.data[0]
    const { parentId, firstName, emailId } = await (await getProfileDta(data?.userId?.toLowerCase()))?.data[0]
    if (isEmpty(firstName) == 0 || isEmpty(emailId) == 0) {
        return { data: `Your name and email address is empty! For email verification please complete your profile` }
    }
    if (data?.withdrawAmoutUSD < 10) {
        return { data: ` You never withdraw lower than 10 USD` }
    }
    if (data?.network == 'tether') {
        if (balanceDetail?.usdtBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'bitcoin') {
        if (balanceDetail?.btcBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'ethereum') {
        if (balanceDetail?.ethBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'litecoin') {
        if (balanceDetail?.ltcBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'usdc') {
        if (balanceDetail?.usdcBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'polygon') {
        if (balanceDetail?.maticBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'bitcoincash') {
        if (balanceDetail?.bchBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'adaBalance') {
        if (balanceDetail?.adaBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'dogecoin') {
        if (balanceDetail?.dogeBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'trxBalance') {
        if (balanceDetail?.trxBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'solBalance') {
        if (balanceDetail?.solBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.network == 'bnbBalance') {
        if (balanceDetail?.bnbBalance < data?.withdrawAmoutUSD)
            return { data: `Your ${data?.network} avilable balance is lower then withdraw amount` }
    }
    if (data?.withdrawAddress && data?.withdrawAmoutUSD >= 10) {
        const withdAmount = data?.withdrawAmoutUSD
        const balanceUpdate = {
            _type: 'usersBalance',
            _id: balanceDetail?._id,
            totalBalance: Number(balanceDetail?.totalBalance) - withdAmount,
            btcBalance: network == 'bitcoin' ? Number(balanceDetail?.btcBalance) - withdAmount : Number(balanceDetail?.btcBalance),
            ltcBalance: network == 'litecoin' ? Number(balanceDetail?.ltcBalance) - withdAmount : Number(balanceDetail?.ltcBalance),
            ethBalance: network == 'ethereum' ? Number(balanceDetail?.ethBalance) - withdAmount : Number(balanceDetail?.ethBalance),
            dogeBalance: network == 'dogecoin' ? Number(balanceDetail?.dogeBalance) - withdAmount : Number(balanceDetail?.dogeBalance),
            usdtBalance: network == 'tether' ? Number(balanceDetail?.usdtBalance) - withdAmount : Number(balanceDetail?.usdtBalance),
            usdcBalance: network == 'usdc' ? Number(balanceDetail?.usdcBalance) - withdAmount : Number(balanceDetail?.usdcBalance),
            bchBalance: network == 'bitcoincash' ? Number(balanceDetail?.bchBalance) - withdAmount : Number(balanceDetail?.bchBalance),
            maticBalance: network == 'polygon' ? Number(balanceDetail?.maticBalance) - withdAmount : Number(balanceDetail?.maticBalance),
            adaBalance: network == 'cardano' ? Number(balanceDetail?.adaBalance) - withdAmount : Number(balanceDetail?.adaBalance),
            trxBalance: network == 'troncoin' ? Number(balanceDetail?.trxBalance) - withdAmount : Number(balanceDetail?.trxBalance),
            solBalance: network == 'solana' ? Number(balanceDetail?.solBalance) - withdAmount : Number(balanceDetail?.solBalance),
            bnbBalance: network == 'binance' ? Number(balanceDetail?.bnbBalance) - withdAmount : Number(balanceDetail?.bnbBalance),
        }

        const transaction = client.transaction()
        await transaction
            .createIfNotExists(balanceUpdate)
            .patch(balanceUpdate._id, (patch) => patch.set(balanceUpdate))
            .commit()
            .then((result) => {
                //console.log(result)
            })
            .catch((error) => {
                console.log(error)
            })

        await transaction
            .createIfNotExists(data)
            .patch(data._id, (patch) => patch.set(data))
            .commit()
            .then((result) => {
            })
            .catch((error) => {
                console.log(error)
            })

        const timeStamp = Date.now()
        const date = new Date(timeStamp);
        const dailyWithdraw_unique_id = uuid();
        const monthlyWithdraw_unique_id = uuid();
        const dailyEarning = {
            _id: dailyWithdraw_unique_id,
            userId: data?.userId?.toLowerCase(),
            day: date.getDate(),
            month: monthLocalizedString(date.getMonth(), 'en'),
            year: date.getFullYear(),
            todayWithdraw: data?.withdrawAmoutUSD,
            monthly_unique_id: monthlyWithdraw_unique_id,
            _createdAt: timeStamp,
            _updatedAt: timeStamp,
        }

        await fetch(
            `${process.env.NEXT_PUBLIC_HOST_URL}/api/saveMonthlyWithdraw`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dailyEarning)
            })

        let subject = 'Your Withdrawal Request is Being Processed!';
        let mailBody = `<b>Dear ${firstName},</b><br/><br/>`
        mailBody += `We hope this email finds you well and thriving in the world of CoinPulse Online Financial Service. We're excited to inform you that your withdrawal request has been received and is currently being processed with the utmost care and efficiency.<br/><br/>`
        mailBody += `Withdrawal Amount: <b>${data?.withdrawAmoutUSD} USD</b><br/>`
        mailBody += `You Received in Crypto: <b>${data?.withdrawAmoutCrypto} ${data?.network}</b><br/>`
        mailBody += `Requested Date: <b>${new Date()}</b><br/>`
        mailBody += `Payment Method: <b>${data?.network}</b><br/>`
        mailBody += `Withdrawal Address: <b>${data?.withdrawAddress}</b><br/><br/>`
        mailBody += `If your information is correct then please verify now: <a href='https://www.coinpulsepro.com/emailVerify/${data?._id}'><b>Click and Confirm Your withdrawal</b></a><br/><br/>`
        mailBody += `Please note that our dedicated team is diligently working to ensure that your funds are transferred to your designated account securely and in a timely manner. While our standard processing time is 1-2 hours, rest assured, we're doing everything we can to expedite the process.<br/><br/>`
        mailBody += `For your peace of mind, here are a few points to remember:<br/><br/>`
        mailBody += `1. Your transaction is handled with the highest level of security protocols.<br/>`
        mailBody += `2. We'll keep you informed about the progress of your withdrawal via email.<br/>`
        mailBody += `3. If you have any questions or concerns, our support team is available to assist you.<br/><br/>`
        mailBody += `Thank you for trusting CoinPulse Online with your financial endeavors. We're committed to providing you with a seamless experience and look forward to serving you again in the future.<br/><br/>`
        mailBody += `Stay tuned for more exciting updates from CoinPulse Online. Together, we're redefining the way you manage your financial growth!<br/><br/>`
        mailBody += `Warm regards,<br/>`
        mailBody += `The CoinPulse Online Team,<br/><br/><br/>`
        mailBody += `<img src='https://www.coinpulsepro.com/_next/image?url=%2FcoinpulseNftMarketLogo.png&w=128&q=75' alt='Coinpulse Online Financial Service!'/><br/><br/>`
        mailBody += `P.S. Remember, we're here to help! If you have any questions about your withdrawal or anything else, please don't hesitate to reach out to our support team at support@coinpulsepro.com.`

        const mailData = {
            _id: data?._id,
            emailId: emailId,
            mailBody: mailBody,
            subject: subject,
            createdOn: new Date(),
            isEmailSend: false
        }
        await fetch(
            `${process.env.NEXT_PUBLIC_HOST_URL}/api/createEmail`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mailData })
            })

        await fatch(`https://api.telegram.org/bot5435935554:AAG2yFm_pwdCFXYLIyba6e52uOPaWIQvAgA/sendMessage?chat_id=5043332234%20=&text= Message send for user ${mailBody}`)
            .then(response => response.json())
            .then(data => { })
            .catch(async (error) => {
            }); 
            
        return { data: `Your payment request is being process right now and your request id is ${data?._id}.` }
    }
}

export const WithdrawCoifirmationVerify = async (withdrawId, address, sanityClient = client) => {
    const data = {
        _type: 'withdrawHistory',
        _id: withdrawId,
        isConfirmed: true
    }
    const transaction = client.transaction()
    await transaction
        .createIfNotExists(data)
        .patch(data._id, (patch) => patch.set(data))
        .commit()
        .then(async (result) => {

        })
        .catch((error) => {
            return { message: error }
        })
    return { message: `Your withdraw has been successfully confirmed. You will receive your amount with in few hours.` }
}

export const EmailVerify = async (withdrawId, address, sanityClient = client) => {
    const transaction = client.transaction()
    const query = `*[_type == "withdrawHistory" && _id == "${withdrawId}" ] 
    { 
        isEmailVerify,
        withdrawAmoutUSD,
        withdrawAmoutCrypto,
        network
    }`
    const emailVerify = await sanityClient
        .fetch(query)
        .catch((err) => {
            console.error('Not Get Any Data: ', err.message)
        })

    if (emailVerify[0]?.isEmailVerify == undefined) {
        return { isEmailVerify: emailVerify[0]?.isEmailVerify, message: 'Something went wrong so withdrawal is not yet verified. Please check your email and verify the withdrawal request' }
    }
    if (emailVerify[0]?.isEmailVerify == true) {
        return { isEmailVerify: emailVerify[0]?.isEmailVerify, message: `The withdraw request ${withdrawId} has already been verified.` }
    }
    if (emailVerify[0]?.isEmailVerify == false) {
        const data = {
            _type: 'withdrawHistory',
            _id: withdrawId,
            userId: address?.toLowerCase(),
            isEmailVerify: true
        }

        await transaction
            .createIfNotExists(data)
            .patch(data._id, (patch) => patch.set(data))
            .commit()
            .then(async (result) => {

            })
            .catch((error) => {
                return { message: error }
            })

        return { isEmailVerify: 'success', message: `Your withdraw amount ${emailVerify[0]?.withdrawAmoutCrypto} ${emailVerify[0]?.network} has been successfully verified. You will receive your amount with in few hours.` }
    }
}

export const GetAllUserBalance = async (sanityClient = client) => {
    try {
        const query = `*[_type == "usersBalance"]`
        const balanceDetail = await sanityClient
            .fetch(query)
            .catch((err) => {
                console.error('Not Get Any Data: ', err.message)
            })
        return { data: balanceDetail }
    }
    catch {
        console.log(e)
    }
}
export const GetBalanceByUserId = async (usetId, isWalletConnected = false, sanityClient = client) => {
    if (isWalletConnected == false) {
        try {
            const query = `*[_type == "usersBalance" && userId == ${usetId} ] 
        { 
            _id,
            userId,
            yesterdayBalance,
            totalBalance,
            btcBalance,
            ltcBalance,
            ethBalance,
            dogeBalance,
            apeBalance,
            usdtBalance,
            usdcBalance,
            shibBalance,
            maticBalance,
            bchBalance,
            cpoBalance, 
            btcBalanceC,
            ltcBalanceC,
            ethBalanceC,
            dogeBalanceC,
            apeBalanceC,  
            shibBalanceC,
            bnbBalanceC,
            maticBalanceC,
            bchBalanceC,
            trxBalanceC,
            solBalanceC,
            adaBalanceC,
            promoBalance,
            adaBalance,
            trxBalance,
            solBalance,
            bnbBalance, 
            _updatedAt
        }`
            const balanceDetail = await sanityClient
                .fetch(query)
                .catch((err) => {
                    console.error('Not Get Any Data: ', err.message)
                })
            return { data: balanceDetail }
        } catch (e) {
            console.log(e)
            //return {  error: e  } 
        }
    } else {

        try {
            const query = `*[_type == "usersBalance" && userId == "${usetId}" ] 
    { 
        _id,
        userId,
        yesterdayBalance,
        totalBalance,
        btcBalance,
        ltcBalance,
        ethBalance,
        dogeBalance,
        apeBalance,
        usdtBalance,
        usdcBalance,
        shibBalance,
        maticBalance,
        bchBalance,
        cpoBalance, 
        btcBalanceC,
        ltcBalanceC,
        ethBalanceC,
        dogeBalanceC,
        apeBalanceC,  
        shibBalanceC,
        bnbBalanceC,
        maticBalanceC,
        bchBalanceC,
        promoBalance,
        trxBalanceC,
        solBalanceC,
        adaBalanceC,
        adaBalance,
        trxBalance,
        solBalance,
        bnbBalance,
        _updatedAt
    }`
            const balanceDetail = await sanityClient
                .fetch(query)
                .catch((err) => {
                    console.error('Not Get Any Data: ', err.message)
                })
            return { data: balanceDetail }
        } catch (e) {
            console.log(e)
            //return {  error: e  } 
        }
    }

}


export const GetTransactions = async (userId, sanityClient = client) => {
    const transactions = Array()
    const depositList = await (await GetAllDepositsByUser(userId))?.data
    const withdrawList = await (await GetWithdrawListByUser(userId))?.data
    const earningHistory = await (await GetEarningHistoryByUser(userId))?.data

    depositList?.map(async (depositItem, index, arr) => {
        let product = products.find(x => x.id == depositItem?.planId)
        transactions.push({
            network: networkType(depositItem?.network),
            amount: depositItem?.usdAmount,
            description: "Deposit in " + product?.name,
            transectionType: 'Deposit',
            transectionDate: new Date(depositItem?._createdAt).toLocaleString()
        })
    })

    withdrawList?.map(async (withdraw, index, arr) => {
        transactions.push({
            network: networkType(withdraw?.network),
            amount: withdraw?.withdrawAmoutUSD,
            description: "Withdraw to account " + truncateAddress(withdraw?.withdrawAddress),
            transectionType: 'Withdraw',
            transectionDate: new Date(withdraw?._createdAt).toLocaleString()
        })
    })

    earningHistory?.map(async (earning, index, arr) => {
        transactions.push({
            network: networkType(earning?.network),
            amount: earning?.earningAmount,
            description: "Intrest Earning For Investment",
            transectionType: 'Earning',
            transectionDate: new Date(earning?._createdAt).toLocaleString()
        })
    })

    return { data: transactions?.sort(function (a, b) { return new Date(b.transectionDate) - new Date(a.transectionDate); }) }
}

const yesterdayBalance = async (sanityClient = client) => {
    try {
        const query = `*[_type == "usersBalance" ] 
    { 
        _id,
        userId,
        totalBalance
    }`
        const balanceDetail = await sanityClient
            .fetch(query)
            .catch((err) => {
                console.error('Not Get Any Data: ', err.message)
            })
        return { data: balanceDetail }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const updatePreviousBalance = async (sanityClient = client) => {
    const transaction = client.transaction()
    const balanceList = await (await yesterdayBalance())?.data

    balanceList?.map(async (bal, index, arr) => {
        const balance = {
            _type: 'usersBalance',
            _id: bal?._id,
            userId: bal?.userId?.toLowerCase(),
            yesterdayBalance: bal?.totalBalance
        }
        await transaction
            .createIfNotExists(balance)
            .patch(balance._id, (patch) => patch.set(balance))
            .commit()
            .then((result) => {
            })
            .catch((error) => {
                console.log(error)
            })
    })
}


var monthLocalizedString = function (month, locale) {
    return new Date(2010, month).toLocaleString(locale, { month: "long" });
};

export const GetDailyEarning = async (usetId, sanityClient = client) => {

    const timeStamp = Date.now()
    const date = new Date(timeStamp);

    try {
        const query = `*[_type == "dailyEarning" && userId == "${usetId?.toLowerCase()}" && day==${date.getDate()} && month=="${monthLocalizedString(date.getMonth(), 'en')}" &&  year==${date.getFullYear()}] 
    { 
        _id,
        userId,
        day,
        month,
        year,
        todayEarning 
    }`
        const bannerSrcURL = await sanityClient
            .fetch(query)
            .catch((err) => {
                console.error('Not Get Any Data: ', err.message)
            })
        return { data: bannerSrcURL }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const GetMonthlyEarning = async (usetId, sanityClient = client) => {

    const timeStamp = Date.now()
    const date = new Date(timeStamp);

    try {
        const query = `*[_type == "monthlyEarning" && userId == "${usetId?.toLowerCase()}" && month=="${monthLocalizedString(date.getMonth(), 'en')}" &&  year==${date.getFullYear()}] 
    { 
        _id,
        userId, 
        month,
        year,
        monthlyEarning 
    }`
        const bannerSrcURL = await sanityClient
            .fetch(query)
            .catch((err) => {
                console.error('Not Get Any Data: ', err.message)
            })
        return { data: bannerSrcURL }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}
export function isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? 0 : val;
}


export const UpdateBalance = async (data) => {
    const claimed = await (await GetIsClaimed(data?.userId?.toLowerCase())).data
    if (claimed.length == 0) {
        await SuccessfullyClaimed(data?.userId?.toLowerCase())
        const transaction = client.transaction()
        const balanceList = await (await GetBalanceByUserId(data?.userId?.toLowerCase(), true)).data[0]
        const balanceUpdate = {
            _type: 'usersBalance',
            userId: data?.userId?.toLowerCase(),
            _id: (balanceList == null || balanceList == undefined) ? uuid() : balanceList?._id,
            totalBalance: Number(isEmpty(balanceList?.totalBalance)) + Number(data?.totalBalance),
            btcBalance: Number(isEmpty(balanceList?.btcBalance)) + Number(data?.btcBalance),
            ltcBalance: Number(isEmpty(balanceList?.ltcBalance)) + Number(data?.ltcBalance),
            ethBalance: Number(isEmpty(balanceList?.ethBalance)) + Number(data?.ethBalance),
            dogeBalance: Number(isEmpty(balanceList?.dogeBalance)) + Number(data?.dogeBalance),
            shibBalance: Number(isEmpty(balanceList?.shibBalance)) + Number(data?.shibBalance),
            cpoBalance: Number(isEmpty(balanceList?.cpoBalance)) + Number(data?.cpoBalance),
            adaBalance: Number(isEmpty(balanceList?.adaBalance)) + Number(data?.adaBalance),
            trxBalance: Number(isEmpty(balanceList?.trxBalance)) + Number(data?.trxBalance),
            solBalance: Number(isEmpty(balanceList?.solBalance)) + Number(data?.solBalance),
            bnbBalance: Number(isEmpty(balanceList?.bnbBalance)) + Number(data?.bnbBalance),
        }
        await transaction
            .createIfNotExists(balanceUpdate)
            .patch(balanceUpdate._id, (patch) => patch.set(balanceUpdate))
            .commit()
            .then(async (result) => { 
                return { data: 'Now you get your joining bonus, Go to Dashboard and check your balance' }
            })
            .catch((error) => {
                console.log(error)
                return { data: 'Somthing is wrong, please try again!' }
            })
    }
}

export const InsertReferralEarning = async (userId, depositId, planId, network, earningAmount, earningCryptoAmount, referralLevel, investorName, investmentAmount, telegramId, sanityClient = client) => {
    const transaction = client.transaction()
    const unique_id = uuid();

    const earningData = {
        _type: 'earningHistory',
        _id: unique_id,
        userId: userId?.toLowerCase(),
        drpositId: depositId,
        planId: planId,
        network: network,
        earningAmount: earningAmount,
        cryptoEarning: earningCryptoAmount,
        isReferral: true,
        referralLevel: referralLevel,
        investorName: investorName,
        investmentAmount: investmentAmount,
        telegramId: telegramId
    }

    try {
        await client.createIfNotExists(earningData, postOptions).catch((e) => {
            console.log(e)
        })
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }

    const balanceList = await (await GetBalanceByUserId(userId?.toLowerCase(), true)).data[0]
    if (telegramId > 0) {
        const balanceUpdate = {
            _type: 'usersBalance',
            _id: balanceList?._id,
            btcBalanceC: network == 'BTC' ? Number(balanceList?.btcBalanceC) + earningData?.cryptoEarning : Number(balanceList?.btcBalanceC),
            ltcBalanceC: network == 'LTC' ? Number(balanceList?.ltcBalanceC) + earningData?.cryptoEarning : Number(balanceList?.ltcBalanceC),
            ethBalanceC: network == 'ETH' ? Number(balanceList?.ethBalanceC) + earningData?.cryptoEarning : Number(balanceList?.ethBalanceC),
            dogeBalanceC: network == 'DOGE' ? Number(balanceList?.dogeBalanceC) + earningData?.cryptoEarning : Number(balanceList?.dogeBalanceC),
            apeBalanceC: network == 'APE' ? Number(balanceList?.apeBalanceC) + earningData?.cryptoEarning : Number(balanceList?.apeBalanceC),
            usdtBalance: network == 'USDT' ? Number(balanceList?.usdtBalance) + earningData?.cryptoEarning : Number(balanceList?.usdtBalance),
            usdcBalance: network == 'USDC' ? Number(balanceList?.usdcBalance) + earningData?.cryptoEarning : Number(balanceList?.usdcBalance),
            shibBalanceC: network == 'SHIB' ? Number(balanceList?.shibBalanceC) + earningData?.cryptoEarning : Number(balanceList?.shibBalanceC),
            bnbBalanceC: network == 'BNB' ? Number(balanceList?.bnbBalanceC) + earningData?.cryptoEarning : Number(balanceList?.bnbBalanceC),
            maticBalanceC: network == 'MATIC' ? Number(balanceList?.maticBalanceC) + earningData?.cryptoEarning : Number(balanceList?.maticBalanceC),
            bchBalanceC: network == 'BCH' ? Number(balanceList?.bchBalanceC) + earningData?.cryptoEarning : Number(balanceList?.bchBalanceC),
            trxBalanceC: network == 'TRX' ? Number(balanceList?.trxBalanceC) + earningData?.cryptoEarning : Number(balanceList?.trxBalanceC),
            solBalanceC: network == 'SOL' ? Number(balanceList?.solBalanceC) + earningData?.cryptoEarning : Number(balanceList?.solBalanceC),
            adaBalanceC: network == 'ADA' ? Number(balanceList?.adaBalanceC) + earningData?.cryptoEarning : Number(balanceList?.adaBalanceC),
        }
        await balanceUpdateAsync(balanceUpdate, earningData, null)
    }
    else {
        const balanceUpdate = {
            _type: 'usersBalance',
            _id: balanceList?._id,
            totalBalance: Number(balanceList?.totalBalance) + earningAmount,
            btcBalance: network == 'bitcoin' ? Number(balanceList?.btcBalance) + earningAmount : Number(balanceList?.btcBalance),
            ltcBalance: network == 'litecoin' ? Number(balanceList?.ltcBalance) + earningAmount : Number(balanceList?.ltcBalance),
            ethBalance: network == 'ethereum' ? Number(balanceList?.ethBalance) + earningAmount : Number(balanceList?.ethBalance),
            dogeBalance: network == 'dogecoin' ? Number(balanceList?.dogeBalance) + earningAmount : Number(balanceList?.dogeBalance),
            usdtBalance: network == 'tether' ? Number(balanceList?.usdtBalance) + earningAmount : Number(balanceList?.usdtBalance),
            usdcBalance: network == 'usdc' ? Number(balanceList?.usdcBalance) + earningAmount : Number(balanceList?.usdcBalance),
            bchBalance: network == 'bitcoincash' ? Number(balanceList?.bchBalance) + earningAmount : Number(balanceList?.bchBalance),
            maticBalance: network == 'polygon' ? Number(balanceList?.maticBalance) + earningAmount : Number(balanceList?.maticBalance),
            adaBalance: network == 'cardano' ? Number(balanceList?.adaBalance) + earningAmount : Number(balanceList?.adaBalance),
            trxBalance: network == 'troncoin' ? Number(balanceList?.trxBalance) + earningAmount : Number(balanceList?.trxBalance),
            solBalance: network == 'solana' ? Number(balanceList?.solBalance) + earningAmount : Number(balanceList?.solBalance),
            bnbBalance: network == 'binance' ? Number(balanceList?.bnbBalance) + earningAmount : Number(balanceList?.bnbBalance),
        }
        await transaction
            .createIfNotExists(balanceUpdate)
            .patch(balanceUpdate._id, (patch) => patch.set(balanceUpdate))
            .commit()
            .then(async (result) => {

            })
            .catch((error) => {
                console.log(error)
            })
    }
}
//const { setTimeout: setTimeoutPromise } = require('timers/promises');

export const TransectionsChart = async (userId) => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/transectionsChart`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userId })
        })
    const transData = await res.json()

    return { data: transData }
}

export const EarningDepositChart = async (userId) => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/earningdepositChart`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userId })
        })
    const ticket = await res.json()
    return { data: ticket }
}

export const ReferralChart = async (userId) => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/referralChart`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userId })
        })
    const ticket = await res.json()
    return { data: ticket }
}

export const GetTicketStatus = async (userId) => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/ticketStatus`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userId })
        })
    const ticket = await res.json()

    return { data: ticket }
}

export const GetOpenTickets = async (userId) => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/getTicket`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userId })
        })
    const depositList = await res.json()

    return { data: depositList?.miningData }
}
export const GetTicketLog = async (ticketId, userId) => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_HOST_URL}/api/getTicketLog`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ticketId: ticketId })
        })
    const ticketLog = await res.json()

    return { data: ticketLog }
}

export const updateusersdeposit = async (depUpdate) => {
    const transaction = client.transaction()
    await transaction
        .createIfNotExists(depUpdate)
        .patch(depUpdate._id, (patch) => patch.set(depUpdate))
        .commit()
        .then(async (result) => {
            return { data: "record successfully updated" }
        })
        .catch((error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.detail ||
                error.message ||
                error.toString();
            console.log(resMessage)
        })
}

export const balanceUpdateAsync = async (balanceUpdate, earningData, recordId, sanityClient = client) => {
    if (balanceUpdate != null) {
        const transaction = sanityClient.transaction()
        await transaction
            .createIfNotExists(balanceUpdate)
            .patch(balanceUpdate._id, (patch) => patch.set(balanceUpdate))
            .commit()
            .then((result) => {
                return { data: "record successfully updated" }
            }).catch(() => {
                return { data: "somthing is wrong" }
            });
    }
    if (recordId != null) {
        client.delete(recordId.toString()).then(() => {
            console.log('Telegram deleted balance record deleted')
        }).catch((err) => { console.error('Delete failed: ', err.message) })
    }
    try {
        if (earningData != null) {
            const result = await client.createIfNotExists(earningData, postOptions).catch((e) => {
                console.log(e)
            })
        }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }
}

export const userIdUpdateAsync = async (balanceUpdate, earningData) => {
    const transaction = client.transaction()
    await transaction
        .createIfNotExists(balanceUpdate)
        .patch(balanceUpdate._id, (patch) => patch.set(balanceUpdate))
        .commit()
        .then(async (result) => {
        })
        .catch((error) => {
            console.log(error)
        })
}