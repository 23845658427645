import sanityClient from '@sanity/client'

export const client = sanityClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-06-07',
  token:process.env.NEXT_PUBLIC_TOKEN_ID,
  useCdn: false,
  ignoreBrowserTokenWarning: true,
})
